import React from "react";
import FabricAndSafetyLink from "../Partials/fabric-safety-link";
import LastUpdated from "../Partials/last-updated";

const InfoPage = ({ heading, body, showSafetyLink, useH2, updatedAt, skipTimestamp }) => (
    <div className="info-page">
        {!useH2 ? (<h1>{heading}</h1>) : (<h2>{heading}</h2>)}
        <div
            className="body"
            dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html
            }}
        />
        {showSafetyLink && <>
            <hr />
            <FabricAndSafetyLink />
        </>}
        {!skipTimestamp && <LastUpdated time={updatedAt} />}
    </div>
);

export default InfoPage;