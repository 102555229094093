import React from "react";
import dayjs from "dayjs";

const renderTime = timestamp => {
    const time = dayjs(timestamp);
    return time.format('ddd D MMM YYYY, h:mma [UTC]Z');
};

const LastUpdated = ({ time }) => <p className="timestamp">
    Last updated: <time dateTime={time}>{renderTime(time)}</time>
</p>;

export default LastUpdated;